const Menu = [
  {
    "name": "French Fries",
    "half": 120,
    "quarter": null,
    "full": 200,
    "family": null,
    "description": "Crispy golden fries, lightly salted and served hot.",
    "category": "Starters",
    "image": "https://imgs.search.brave.com/LnFEK3lm1FdmNL5kAuk3LC5e0u5g_lQufe8WNOHQTFA/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5nZXR0eWltYWdl/cy5jb20vaWQvMTcx/MTU2MzU5L3Bob3Rv/L2ZyZW5jaC1mcmll/cy13aXRoLWtldGNo/dXAuanBnP3M9NjEy/eDYxMiZ3PTAmaz0y/MCZjPUtxd3pISnlf/Qm13NWVYWkxTUFll/clNqWnJ2S09MNjhZ/cGE1RlJJTm02OXc9"
  },
  {
    "name": "Peri Peri French Fries",
    "half": 140,
    "quarter": null,
    "full": 220,
    "family": null,
    "description": "Spicy peri peri coated fries with a tangy seasoning.",
    "category": "Starters",
    "image": "https://imgs.search.brave.com/fmuMLaK7k1zqMRwpvAorUqFh588-VIUX-hcgjkORA2s/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pMC53/cC5jb20vd3d3LnNw/bGFzaG9mdGFzdGUu/Y29tL3dwLWNvbnRl/bnQvdXBsb2Fkcy8y/MDIyLzA1L3Blcmkt/cGVyaS1mcmllcy03/LTc2OHgxMDI0Lmpw/Zw"
  },
  {
    "name": "Paneer Tikka",
    "half": 140,
    "quarter": null,
    "full": 260,
    "family": null,
    "description": "Grilled paneer cubes marinated with spices and yogurt.",
    "category": "Starters",
    "image": "https://media.gettyimages.com/id/1334104009/photo/paneer-tikka-with-mint-chutney-and-onion-rings.jpg?s=612x612&w=0&k=20&c=w9jEH7tV7xPumzxEsRm1r4rHQqWr23GlzK_R0Y0GTHg="
  },
  {
    "name": "Veg Shami Kebab",
    "half": 50,
    "quarter": null,
    "full": 100,
    "family": null,
    "description": "Vegetarian kebabs made with lentils and spices.",
    "category": "Starters",
    "image": "https://media.istockphoto.com/id/1456235385/photo/vegetable-pakora-or-shami-kabab-tikki-with-chutney-sauce-and-dip-served-in-dish-isolated-on.jpg?s=612x612&w=0&k=20&c=-da9LBr1tWd_m7snpL2oUZ3L6zXmYRjme5IbA1mWJ9E="
  },
  {
    "name": "Tandoori Aloo",
    "half": 120,
    "quarter": null,
    "full": 220,
    "family": null,
    "description": "Char-grilled baby potatoes marinated in a spiced yogurt mix.",
    "category": "Starters",
    "image": "https://www.indianveggiedelight.com/wp-content/uploads/2021/08/air-fryer-tandoori-aloo.jpg"
  },
  {
    "name": "LaLa's Special Resha Kebab",
    "half": 90,
    "quarter": null,
    "full": 160,
    "family": null,
    "description": "Unique shredded kebab with signature spices.",
    "category": "Starters",
    "image": "https://imgs.search.brave.com/QRAIPnQoDqeqm1eSB5lf0qbO7VudjOfRw6gDCT5dSXw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5pc3RvY2twaG90/by5jb20vaWQvMTU1/MzU0MDQ5L3Bob3Rv/L2tlYmFiLmpwZz9z/PTYxMng2MTImdz0w/Jms9MjAmYz13QUlI/aWhQQThPX3pkaWxo/UGdtZVVWaGlwTlJH/dk0tbERYX0d5ZnJT/ZldBPQ"
  },
  {
    "name": "Mutton Shami Kebab",
    "half": 60,
    "quarter": null,
    "full": 120,
    "family": null,
    "description": "Rich mutton kebabs prepared with lentils and spices.",
    "category": "Starters",
    "image": "https://imgs.search.brave.com/i_BeGQIbuSkv4-2VjniW_fAAwk0dcA8EkYEvP9BykyY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzA4LzMxLzczLzkz/LzM2MF9GXzgzMTcz/OTM3M182YTlvajZm/cllKSmVHZmNBSkZs/T1c3eGs0U1lNbGdy/Ni5qcGc"
  },
  {
    "name": "Chicken Tikka",
    "half": 150,
    "quarter": null,
    "full": 280,
    "family": null,
    "description": "Juicy grilled chicken pieces marinated with Indian spices.",
    "category": "Starters",
    "image": "https://imgs.search.brave.com/WepcE5NFXYCiEvB9SCqOCrURsiW5An0PhKkhhXgL124/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90My5m/dGNkbi5uZXQvanBn/LzAzLzMzLzA0LzAy/LzM2MF9GXzMzMzA0/MDI1OV9YQW5nQ2ZW/TVZybVpiSlhEM2Zx/SDREVmhNYVRLN0ti/Vi5qcGc"
  },
  {
    "name": "Chicken Roasted",
    "half": 150,
    "quarter": null,
    "full": 280,
    "family": null,
    "description": "Roasted chicken seasoned with herbs and spices.",
    "category": "Starters",
    "image": "https://imgs.search.brave.com/LDC6lHqk5CxNyrDIKZ1jqNnf5lZb3fm7JSX5QpyAO1I/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9ncmlsbGVkLXJv/YXN0ZWQtd2hvbGUt/Y2hpY2tlbl84OTgx/Ni0xODg3NS5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw"
  },
  {
    "name": "Paneer Masala Roll",
    "price": 80,
    "half": null,
    "quarter": null,
    "full": null,
    "family": null,
    "description": "Delicious roll with spicy paneer filling wrapped in a paratha.",
    "category": "Rolls",
    "image": "https://imgs.search.brave.com/miSXBp5eyF84pECb4qoMkQH8u9vE-FJ2kNaB7M2v6S0/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9jZG4y/LmZvb2R2aXZhLmNv/bS9zdGF0aWMtY29u/dGVudC9mb29kLWlt/YWdlcy9raWRzLXJl/Y2lwZXMvcGFuZWVy/LXJvbGwtcGFuZWVy/LXdyYXAtcmVjaXBl/L3BhbmVlci1yb2xs/LXBhbmVlci13cmFw/LXJlY2lwZS5qcGc"
  },
  {
    "name": "Paneer Butter Masala Roll",
    "price": 90,
    "half": null,
    "quarter": null,
    "full": null,
    "family": null,
    "description": "Creamy and rich paneer butter masala rolled in a paratha.",
    "category": "Rolls",
    "image": `./assets/image/MenuImages/Paneer Butter Masala Roll.jpeg`
  },
  {
    "name": "Egg Roll",
    "price": 70,
    "half": null,
    "quarter": null,
    "full": null,
    "family": null,
    "description": "Flavorful chicken roll spiced and wrapped in a paratha.",
    "category": "Rolls",
    "image": "./assets/image/MenuImages/Egg Roll.jpeg"
  },
  {
    "name": "Butter Chicken Roll",
    "price": 90,
    "half": null,
    "quarter": null,
    "full": null,
    "family": null,
    "description": "Flavorful chicken roll spiced and wrapped in a paratha.",
    "category": "Rolls",
    "image": "./assets/image/MenuImages/Butter Chiken Roll.jpeg"
  },
  {
    "name": "Chicken Masala Roll",
    "price": 80,
    "half": null,
    "quarter": null,
    "full": null,
    "family": null,
    "description": "Vegetable rolls with a medley of spices in a paratha.",
    "category": "Rolls",
    "image": "./assets/image/MenuImages/Chicken Masala Roll.jpeg"
  },
  {
    "name": "Mutton Bhuna Roll",
    "price": 100,
    "half": null,
    "quarter": null,
    "full": null,
    "family": null,
    "description": "Flavorful chicken roll spiced and wrapped in a paratha.",
    "category": "Rolls",
    "image": "./assets/image/MenuImages/Mutton Bhuna Roll.jpeg"
  },
  {
    "name": "Plain Rice",
    "quarter": null,
    "half": 110,
    "full": 200,
    "family": null,
    "description": "Stir-fried rice with assorted vegetables and spices.",
    "category": "Rice",
    "image": "./assets/image/MenuImages/Veg Fried Rice.jpeg"
  },
  {
    "name": "Jeera Rice",
    "quarter": null,
    "half": 120,
    "full": 220,
    "family": null,
    "description": "Fried rice with scrambled eggs and vegetables.",
    "category": "Rice",
    "image": "./assets/image/MenuImages/Egg Fried Rice.jpeg"
  },
  {
    "name": "Biryani Rice",
    "quarter": 50,
    "half": 100,
    "full": 200,
    "family": null,
    "description": "Fragrant basmati rice with mixed vegetables and spices.",
    "category": "Rice",
    "image": "./assets/image/MenuImages/Biryani Rice.jpeg"
  },
  {
    "name": "Veg Biryani",
    "quarter": null,
    "half": 120,
    "full": 240,
    "family": null,
    "description": "Biryani made with flavorful rice and boiled eggs.",
    "category": "Rice",
    "image": "./assets/image/MenuImages/Veg Biryani.jpeg"
  },
  {
    "name": "Chicken Biryani",
    "quarter": 80,
    "half": 130,
    "full": 260,
    "family": null,
    "description": "Aromatic basmati rice cooked with mixed vegetables.",
    "category": "Rice",
    "image": "./assets/image/MenuImages/Chicken Biryani.jpeg"
  },
  {
    "name": "Mutton Biryani",
    "half": 150,
    "quarter": 90,
    "full": 300,
    "family": null,
    "description": "Flavorful biryani made with chicken and spices.",
    "category": "Rice",
    "image": "./assets/image/MenuImages/Mutton Biryani.jpeg"
  },
  {
    "name": "Boiled Chicken with Salad",
    "quarter": null,
    "half": 120,
    "full": 230,
    "family": null,
    "category": "Gym Freaks",
    "description": "Boiled chicken breast with fresh salad and vinaigrette dressing.",
    "image": "./assets/image/MenuImages/BoiledChickenWithSalad.jpeg"
  },
  {
    "name": "Air Fried Peri Peri Chicken",
    "quarter": null,
    "half": 130,
    "full": 250,
    "family": null,
    "category": "Gym Freaks",
    "description": "Air-fried chicken with tangy, spicy peri peri sauce.",
    "image": "./assets/image/MenuImages/AirFriedPeriPeriChicken.jpeg"
  },
  {
    "name": "Breast with Tandoori Sauce",
    "quarter": null,
    "half": 120,
    "full": 230,
    "family": null,
    "category": "Gym Freaks",
    "description": "Grilled chicken breast with traditional tandoori spices.",
    "image": "./assets/image/MenuImages/BreastWithTandooriSauce.jpeg"
  },
  {
    "name": "Air Fried Peri Peri Paneer with Tandoori Sauce",
    "quarter": null,
    "half": 120,
    "full": 230,
    "family": null,
    "category": "Gym Freaks",
    "description": "Air-fried paneer with peri peri and tandoori flavors.",
    "image": "./assets/image/MenuImages/AirFriedPeriPeriPaneerWithTandooriSauce.jpeg"
  },
  {
    "name": "Tandoori Sauce",
    "quarter": null,
    "half": 120,
    "full": 230,
    "family": null,
    "category": "Gym Freaks",
    "description": "Aromatic sauce with yogurt, spices, and lemon juice.",
    "image": "./assets/image/MenuImages/TandooriSauce.jpeg"
  },
  {
    "name": "Kadhai Paneer",
    "quarter": null,
    "half": 150,
    "full": 280,
    "family": 600,
    "category": "Veg Main Course",
    "description": "Paneer with tomatoes, onions, bell peppers, and spices.",
    "image": "./assets/image/MenuImages/Kadhai Paneer.jpeg"
  },
  {
    "name": "Paneer Butter Masala",
    "quarter": null,
    "half": 150,
    "full": 280,
    "family": 600,
    "category": "Veg Main Course",
    "description": "Paneer in creamy tomato-butter gravy with spices.",
    "image": "./assets/image/MenuImages/Paneer Butter Masala.jpeg"
  },
  {
    "name": "Handi Paneer",
    "quarter": null,
    "half": 150,
    "full": 280,
    "family": 600,
    "category": "Veg Main Course",
    "description": "Paneer cooked with onions, tomatoes, and spices.",
    "image": "./assets/image/MenuImages/Handi Paneer.jpeg"
  },
  {
    "name": "Paneer Do Pyaza",
    "quarter": null,
    "half": 150,
    "full": 280,
    "family": 600,
    "category": "Veg Main Course",
    "description": "Paneer with onions and aromatic spices.",
    "image": "./assets/image/MenuImages/Paneer Do Pyaza.jpeg"
  },
  {
    "name": "Paneer Kolhapuri",
    "quarter": null,
    "half": 150,
    "full": 300,
    "family": 600,
    "category": "Veg Main Course",
    "description": "Spicy paneer curry from Kolhapur, Maharashtra.",
    "image": "./assets/image/MenuImages/Paneer Kolhapuri.jpeg"
  },
  {
    "name": "Paneer Lababdar",
    "quarter": null,
    "half": 160,
    "full": 300,
    "family": 620,
    "category": "Veg Main Course",
    "description": "Paneer curry with rich gravy of cashews and almonds.",
    "image": "./assets/image/MenuImages/Paneer Lababdar.jpeg"
  },
  {
    "name": "Shahi Paneer",
    "quarter": null,
    "half": 160,
    "full": 300,
    "family": 620,
    "category": "Veg Main Course",
    "description": "Paneer in creamy gravy with cashews and almonds.",
    "image": "./assets/image/MenuImages/Shahi Paneer.jpeg"
  },
  {
    "name": "Matar Paneer",
    "quarter": null,
    "half": 160,
    "full": 300,
    "family": 620,
    "category": "Veg Main Course",
    "description": "Paneer with peas in tomato-onion gravy.",
    "image": "./assets/image/MenuImages/Matar Paneer.jpeg"
  },
  {
    "name": "Paneer Tak A Tak",
    "quarter": null,
    "half": 160,
    "full": 300,
    "family": 620,
    "category": "Veg Main Course",
    "description": "Paneer stir-fried with onions, tomatoes, and bell peppers.",
    "image": "./assets/image/MenuImages/Paneer Tak A Tak.jpeg"
  },
  {
    "name": "Paneer Tikka Masala",
    "quarter": null,
    "half": 160,
    "full": 300,
    "family": 620,
    "category": "Veg Main Course",
    "description": "Grilled paneer in spiced tomato gravy.",
    "image": "./assets/image/MenuImages/Paneer Tikka Masala.jpeg"
  },
  {
    "name": "Mushroom Do Pyaza",
    "quarter": null,
    "half": 150,
    "full": 280,
    "family": 600,
    "category": "Veg Main Course",
    "description": "Mushrooms cooked with onions and spices.",
    "image": "./assets/image/MenuImages/Mushroom Do Pyaza.jpeg"
  },
  {
    "name": "Mushroom Matar Masala",
    "quarter": null,
    "half": 160,
    "full": 300,
    "family": 620,
    "category": "Veg Main Course",
    "description": "Mushrooms with peas in spiced gravy.",
    "image": "./assets/image/MenuImages/Mushroom Matar Masala.jpeg"
  },
  {
    "name": "Mushroom Tak A Tak",
    "quarter": null,
    "half": 160,
    "full": 300,
    "family": 620,
    "category": "Veg Main Course",
    "description": "Mushrooms stir-fried with onions and bell peppers.",
    "image": "./assets/image/MenuImages/Mushroom Tak A Tak.jpg"
  },
  {
    "name": "Dum Aloo Banarsi",
    "quarter": null,
    "half": 140,
    "full": 250,
    "family": 500,
    "category": "Veg Main Course",
    "description": "Baby potatoes in yogurt gravy with spices.",
    "image": "./assets/image/MenuImages/Dum Aloo Banarsi.jpeg"
  },
  {
    "name": "Kadhai Mushroom",
    "quarter": null,
    "half": 150,
    "full": 280,
    "family": 620,
    "category": "Veg Main Course",
    "description": "Mushrooms with onions, tomatoes, and bell peppers.",
    "image": "./assets/image/MenuImages/Kadhai Mushroom.jpeg"
  },
  {
    "name": "Paneer Bhurji",
    "quarter": null,
    "half": 170,
    "full": 320,
    "family": 620,
    "description": "Scrambled paneer with onions, tomatoes, and spices.",
    "image": "./assets/image/MenuImages/Paneer Bhurji.png",
    "category": "Dal & Dry Veg"
  },
  {
    "name": "Aloo Jeera",
    "quarter": null,
    "half": 120,
    "full": 210,
    "family": 400,
    "description": "Potatoes cooked with cumin seeds and spices.",
    "image": "./assets/image/MenuImages/Aloo Jeera.png",
    "category": "Dal & Dry Veg"
  },
  {
    "name": "Dal Makhani",
    "quarter": null,
    "half": 130,
    "full": 240,
    "family": 480,
    "description": "Lentil curry with butter and cream.",
    "image": "./assets/image/MenuImages/Dal Makhani.png",
    "category": "Dal & Dry Veg"
  },
  {
    "name": "Dal Tadka",
    "quarter": null,
    "half": 120,
    "full": 200,
    "family": 400,
    "description": "Lentil curry with ghee, garlic, and spices.",
    "image": "./assets/image/MenuImages/Dal Tadka.png",
    "category": "Dal & Dry Veg"
  },
  {
    "name": "Dal Fry",
    "quarter": null,
    "half": 120,
    "full": 200,
    "family": 400,
    "description": "Lentils cooked with spices and tempered with ghee.",
    "image": "./assets/image/MenuImages/Dal Fry.jpeg",
    "category": "Dal & Dry Veg"
  },
  {
    "name": "Mushroom Masala",
    "quarter": null,
    "half": 160,
    "full": 300,
    "family": 620,
    "description": "Mushrooms in spiced tomato gravy with onions and garlic.",
    "image": "./assets/image/MenuImages/Mushroom Masala.jpeg",
    "category": "Dal & Dry Veg"
  },
  {
    "name": "Aloo Matar",
    "quarter": null,
    "half": 130,
    "full": 250,
    "family": 480,
    "description": "Potatoes and peas in spiced tomato gravy.",
    "image": "./assets/image/MenuImages/Aloo Matar.jpeg",
    "category": "Dal & Dry Veg"
  },
  {
    "name": "Smoky Butter Chicken",
    "quarter": 180,
    "half": 340,
    "full": 670,
    "family": 1090,
    "description": "Chicken in creamy butter gravy with a smoky touch.",
    "image": "./assets/image/MenuImages/Smoky Butter Chicken.jpeg",
    "category": "Non Veg Main Course"
  },
  {
    "name": "Boneless Butter Chicken",
    "quarter": 200,
    "half": 380,
    "full": 750,
    "family": 1170,
    "description": "Boneless chicken in rich, creamy butter gravy.",
    "image": "./assets/image/MenuImages/Boneless Butter Chicken.jpeg",
    "category": "Non Veg Main Course"
  },
  {
    "name": "Chicken Do Pyaza",
    "quarter": 180,
    "half": 340,
    "full": 670,
    "family": 1090,
    "description": "Chicken cooked with onions and spices.",
    "image": "./assets/image/MenuImages/Chicken Do Pyaza.jpeg",
    "category": "Non Veg Main Course"
  },
  {
    "name": "Handi Chicken",
    "quarter": 190,
    "half": 360,
    "full": 710,
    "family": 1130,
    "description": "Chicken slow-cooked in rich, spiced gravy.",
    "image": "./assets/image/MenuImages/Handi Chicken.jpeg",
    "category": "Non Veg Main Course"
  },
  {
    "name": "Chicken Lababdar",
    "quarter": 200,
    "half": 380,
    "full": 750,
    "family": 1170,
    "description": "Chicken in creamy tomato gravy with butter.",
    "image": "./assets/image/MenuImages/Chicken Lababdar.jpeg",
    "category": "Non Veg Main Course"
  },
  {
    "name": "Chicken Tikka Masala",
    "quarter": 200,
    "half": 380,
    "full": 760,
    "family": 1170,
    "description": "Marinated chicken in spiced tomato gravy.",
    "image": "./assets/image/MenuImages/Chicken Tikka Masala.jpeg",
    "category": "Non Veg Main Course"
  },
  {
    "name": "Chicken Kali Mirch",
    "quarter": 190,
    "half": 360,
    "full": 730,
    "family": 1130,
    "description": "Chicken with black pepper and spices.",
    "image": "./assets/image/MenuImages/Chicken Kali Mirch.jpeg",
    "category": "Non Veg Main Course"
  },
  {
    "name": "Chicken Tangri Masala",
    "quarter": 200,
    "half": 360,
    "full": 760,
    "family": 1170,
    "description": "Chicken drumsticks in spicy gravy.",
    "image": "./assets/image/MenuImages/Chicken Tangri Masala.jpeg",
    "category": "Non Veg Main Course"
  },
  {
    "name": "Kadhai Chicken",
    "quarter": 190,
    "half": 360,
    "full": 730,
    "family": 1130,
    "description": "Chicken in tomato gravy with bell peppers and onions.",
    "image": "./assets/image/MenuImages/Kadhai Chicken.jpeg",
    "category": "Non Veg Main Course"
  },
  {
    "name": "Mutton Tawa Masala",
    "quarter": 210,
    "half": 400,
    "full": 780,
    "family": 1210,
    "description": "Spicy mutton curry cooked on tawa with onions and spices.",
    "image": "./assets/image/MenuImages/Mutton Tawa Masala.jpeg",
    "category": "Mutton Curries"
  },
  {
    "name": "Mutton Rogan Josh",
    "quarter": 220,
    "half": 420,
    "full": 800,
    "family": 1250,
    "description": "Slow-cooked mutton in rich gravy with Kashmiri spices.",
    "image": "./assets/image/MenuImages/Mutton Rogan Josh.jpeg",
    "category": "Mutton Curries"
  },
  {
    "name": "Mutton Do Pyaza",
    "quarter": 210,
    "half": 400,
    "full": 780,
    "family": 1210,
    "description": "Mutton cooked with onions and aromatic Indian spices.",
    "image": "./assets/image/MenuImages/Mutton Do Pyaza.jpeg",
    "category": "Mutton Curries"
  },
  {
    "name": "Mutton Bhuna Gosht",
    "quarter": 220,
    "half": 420,
    "full": 800,
    "family": 1250,
    "description": "Mutton slow-cooked in rich gravy for deep flavors.",
    "image": "./assets/image/MenuImages/Mutton Bhuna Gosht.png",
    "category": "Mutton Curries"
  },
  {
    "name": "Kadhai Mutton",
    "quarter": 210,
    "half": 400,
    "full": 780,
    "family": 1210,
    "description": "Mutton in spicy gravy with onions and bell peppers.",
    "image": "./assets/image/MenuImages/Kadhai Mutton.jpeg",
    "category": "Mutton Curries"
  },
  {
    "name": "Egg Curry",
    "quarter": 80,
    "half": 160,
    "full": 350,
    "family": null,
    "description": "Boiled eggs in spicy tomato gravy with aromatic spices.",
    "image": "./assets/image/MenuImages/Egg Curry.jpeg",
    "category": "Egg & Fish Curries"
  },
  {
    "name": "Fish Curry",
    "quarter": 350,
    "half": 700,
    "full": 1000,
    "family": null,
    "description": "Fish cooked in tangy tamarind gravy with spices.",
    "image": "./assets/image/MenuImages/Fish Curry.jpeg",
    "category": "Egg & Fish Curries"
  },
  {
    "name": "Egg Bhurji",
    "quarter": 90,
    "half": 180,
    "full": 350,
    "family": null,
    "description": "Scrambled eggs with onions, tomatoes, and spices.",
    "image": "./assets/image/MenuImages/Egg Bhurji.jpeg",
    "category": "Egg & Fish Curries"
  },
  {
    "name": "Naan",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
    "price": 45,
    "description": "Leavened bread made with wheat flour.",
    "image": "./assets/image/MenuImages/Naan.jpg",
    "category": "Breads"
  },
  {
    "name": "Tandoori Roti",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
    "price": 20,
    "description": "Leavened bread cooked in a tandoor (clay oven).",
    "image": "./assets/image/MenuImages/Tandoori Roti.jpg",
    "category": "Breads"
  },
  {
    "name": "Laccha Paratha",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
    "price": 35,
    "description": "Flaky layered bread made with wheat flour.",
    "image": "./assets/image/MenuImages/Laccha Paratha.webp",
    "category": "Breads"
  },
  {
    "name": "Butter Naan",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
    "price": 50,
    "description": "Naan bread brushed with butter.",
    "image": "./assets/image/MenuImages/Butter Naan.jpeg",
    "category": "Breads"
  },
  {
    "name": "Rumali Roti",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
    "price": 15,
    "description": "Thin, soft bread often used for wrapping kebabs.",
    "image": "./assets/image/MenuImages/Rumali Roti.webp",
    "category": "Breads"
  },
  {
    "name": "Tawa Roti",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
    "price": 10,
    "description": "Simple, unleavened bread cooked on a tawa (griddle).",
    "image": "./assets/image/MenuImages/Tawa Roti.jpeg",
    "category": "Breads"
  },
  {
    "name": "Tandoori Butter Roti",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
    "price": 25,
    "description": "Simple, unleavened bread cooked on a tawa (griddle).",
    "image": "./assets/image/MenuImages/Tandoori Butter Roti.jpeg",
    "category": "Breads"
  },
  {
    "name": "Onion Salad",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
    "price": 90,
    "description": "Chopped onions with a tangy dressing.",
    "image": "./assets/image/MenuImages/Onion Salad.jpeg",
    "category": "Salad"
  },
  {
    "name": "Green Salad",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
    "price": 80,
    "description": "Mixed greens with a vinaigrette dressing.",
    "image": "./assets/image/MenuImages/Green Salad.jpeg",
    "category": "Salad"
  },
  {
    "name": "Plain Curd",

    "price": 100,
    "description": "Plain yogurt.",
    "image": "./assets/image/MenuImages/Plain Curd.webp",
    "category": "Raitas"
  },
  {
    "name": "Boondi Raita",

    "price": 110,
    "description": "Yogurt-based raita with crispy fried gram flour balls.",
    "image": "./assets/image/MenuImages/Boondi Raita.png",
    "category": "Raitas"
  },
  {
    "name": "Dal Makhani/Arhar Dal/Dal Tadka/Banarsi Paneer",

    "price": 150,
    "description": "Combo with Dal Makhani, Arhar Dal, Dal Tadka, or Banarsi Paneer.",
    "image": "./assets/image/MenuImages/Dal Makhani Arhar Dal Dal Tadka Banarsi Paneer.png",
    "category": "Bread & Curry Combo"
  },
  {
    "name": "Butter Chicken/Chicken Masala/Chicken Do Pyaza",

    "price": 175,
    "description": "Combo with Butter Chicken, Chicken Masala, or Chicken Do Pyaza.",
    "image": "./assets/image/MenuImages/Butter Chicken Chicken Masala Chicken Do Pyaza.png",
    "category": "Bread & Curry Combo"
  },
  {
    "name": "Paneer Butter Masala/Kadhai Paneer/Handi Paneer",
    "price": 155,
    "description": "Rice bowl combo with Paneer Butter Masala, Kadhai Paneer, or Handi Paneer.",
    "image": "./assets/image/MenuImages/Paneer Butter Masala Kadhai Paneer Handi Paneer.jpg",
    "category": "Rice Bowl Combo",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
  },
  {
    "name": "Dal Makhani/Arhar Dal/Dal Tadka/Banarsi Paneer",
    "price": 145,
    "description": "Rice bowl combo with Dal Makhani, Arhar Dal, Dal Tadka, or Banarsi Paneer.",
    "image": "./assets/image/MenuImages/Dal Makhani Arhar Dal Dal Tadka Banarsi Paneer.png",
    "category": "Rice Bowl Combo",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
  },
  {
    "name": "Butter Chicken/Chicken Masala/Chicken Do Pyaza",
    "price": 175,
    "description": "Rice bowl combo with Butter Chicken, Chicken Masala, or Chicken Do Pyaza.",
    "image": "./assets/image/MenuImages/Butter Chicken Chicken Masala Chicken Do Pyaza.png",
    "category": "Rice Bowl Combo",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
  },
  {
    "name": "2 Pcs. Veg Shami Kebab with 2 Rumali Roti",
    "price": 100,
    "description": "Two vegetarian shami kebabs served with two rumali rotis.",
    "image": "./assets/image/MenuImages/Veg Shami Kebab.png",
    "category": "Kebab Combo",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
  },
  {
    "name": "2 Pcs. Reshmi Kebab with 2 Rumali Roti",
    "price": 150,
    "description": "Two reshmi kebabs served with two rumali rotis.",
    "image": "./assets/image/MenuImages/Reshmi Kebab.png",
    "category": "Kebab Combo",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
  },
  {
    "name": "2 Pcs. Mutton Shami Kebab with 2 Rumali Roti",
    "price": 100,
    "description": "Two mutton shami kebabs served with two rumali rotis.",
    "image": "./assets/image/MenuImages/Shami Kebab.png",
    "category": "Kebab Combo",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
  },
  {
    "name": "Paneer Curry + Dalum Aloo + Dal Tadka + 2 Pcs. Baby Breads + Raita + Salad + Sweet",
    "price": 230,
    "description": "A deluxe thali with paneer curry, dalum aloo, dal tadka, baby breads, raita, salad, and sweet.",
    "image": "./assets/image/MenuImages/DeluxThaliPaneer.png",
    "category": "Veg Deluxe Thali",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
  },
  {
    "name": "1 Pcs. Chicken Curry + 2 Pcs. Kebab + 1 Pcs. Biryani + 2 Pcs. Baby Breads + Raita + Salad + Sweet",

    "price": 280,
    "description": "A deluxe thali with chicken curry, two kebabs, biryani, baby breads, raita, salad, and sweet.",
    "image": "./assets/image/MenuImages/Delux Thali Baby Breads.png",
    "category": "Non Veg Deluxe Thali",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null,
  },
  {
    "name": "Chilli Potato",
    "price": null,
    "description": "Crispy potatoes tossed in tangy chili sauce for a delightful treat.",
    "image": "./assets/image/MenuImages/Chilli Potato.jpeg",
    "category": "Veg Chinese Starters",
    "quarter": null,
    "half": 120,
    "full": 220,
    "family": null
  },
  {
    "name": "Honey Chilli Potato",
    "price": null,
    "description": "Sweet and spicy potatoes with a honey-chili glaze for a tasty twist.",
    "image": "./assets/image/MenuImages/Honey Chilli Potato.jpeg",
    "category": "Veg Chinese Starters",
    "quarter": null,
    "half": 130,
    "full": 230,
    "family": null
  },
  {
    "name": "Paneer Chilli Dry(8/16 Pcs)",
    "price": null,
    "description": "Crispy paneer cubes sautéed with spicy chili sauce, a perfect starter.",
    "image": "./assets/image/MenuImages/Paneer_Chilli_Dry.jpg",
    "category": "Veg Chinese Starters",
    "quarter": null,
    "half": 160,
    "full": 280,
    "family": null
  },
  {
    "name": "Paneer 65 (8/16 Pcs)",
    "price": null,
    "description": "Paneer cubes marinated with spices and fried to crispy perfection.",
    "image": "./assets/image/MenuImages/Paneer 65.jpg",
    "category": "Veg Chinese Starters",
    "quarter": null,
    "half": 160,
    "full": 280,
    "family": null
  },
  {
    "name": "Mushroom Chilli Dry",
    "price": null,
    "description": "Juicy mushrooms stir-fried in a spicy chili sauce for mushroom lovers.",
    "image": "./assets/image/MenuImages/Mushroom Chilli Dry.jpeg",
    "category": "Veg Chinese Starters",
    "quarter": null,
    "half": 160,
    "full": 280,
    "family": null
  },
  {
    "name": "Veg Manchurian Dry (8/16 Pcs)",
    "price": null,
    "description": "Spicy vegetable balls in a dry Manchurian sauce, a perfect appetizer.",
    "image": "./assets/image/MenuImages/Veg Manchurian Dry.jpeg",
    "category": "Veg Chinese Starters",
    "quarter": null,
    "half": 150,
    "full": 260,
    "family": null
  },
  {
    "name": "Chilli Chicken Dry (8/16 Pcs)",
    "price": null,
    "description": "Tender chicken tossed in a fiery chili sauce for an extra kick.",
    "image": "./assets/image/MenuImages/Chilli Chicken Dry.jpg",
    "category": "Non-Veg Chinese Starters",
    "quarter": null,
    "half": 190,
    "full": 320,
    "family": null
  },
  {
    "name": "Chicken 65 (8/16 Pcs)",
    "price": null,
    "description": "Spicy, crispy chicken chunks with traditional Indian spices.",
    "image": "./assets/image/MenuImages/Chicken 65.jpg",
    "category": "Non-Veg Chinese Starters",
    "quarter": null,
    "half": 190,
    "full": 320,
    "family": null
  },
  {
    "name": "Chicken Lollipop (5 Pcs)",
    "price": 350,
    "description": "Deep-fried chicken drumsticks, a favorite snack with spicy flavors.",
    "image": "./assets/image/MenuImages/Chicken Lollipop.jpeg",
    "category": "Non-Veg Chinese Starters",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null
  },
  {
    "name": "Chicken Wings (5/10 Pcs)",
    "price": null,
    "description": "Juicy chicken wings coated with spicy sauces for ultimate flavor.",
    "image": "./assets/image/MenuImages/Chicken Wings.jpeg",
    "category": "Non-Veg Chinese Starters",
    "quarter": null,
    "half": 190,
    "full": 320,
    "family": null
  },
  {
    "name": "Veg Fried Rice",
    "price": null,
    "description": "Classic fried rice with mixed vegetables, a flavorful side dish.",
    "image": "./assets/image/MenuImages/Veg Fried Rice.jpeg",
    "category": "Fried Rice",
    "quarter": null,
    "half": 120,
    "full": 220,
    "family": null
  },
  {
    "name": "Paneer Fried Rice",
    "price": null,
    "description": "Delicious fried rice with paneer and vegetables for added texture.",
    "image": "./assets/image/MenuImages/Paneer Fried Rice.jpg",
    "category": "Fried Rice",
    "quarter": null,
    "half": 140,
    "full": 240,
    "family": null
  },
  {
    "name": "Veg Schezwan Fried Rice",
    "price": null,
    "description": "Spicy Schezwan-style fried rice with mixed veggies for extra heat.",
    "image": "./assets/image/MenuImages/Veg Schezwan Fried Rice.jpg",
    "category": "Fried Rice",
    "quarter": null,
    "half": 130,
    "full": 230,
    "family": null
  },
  {
    "name": "Veg Chilli Garlic Fried Rice",
    "price": null,
    "description": "Garlic-flavored fried rice with a hint of chili for extra zest.",
    "image": "./assets/image/MenuImages/Veg Chilli Garlic Fried Rice.jpeg",
    "category": "Fried Rice",
    "quarter": null,
    "half": 130,
    "full": 230,
    "family": null
  },
  {
    "name": "Egg Fried Rice",
    "price": null,
    "description": "Flavorful fried rice with egg and vegetables for a balanced meal.",
    "image": "./assets/image/MenuImages/Egg Fried Rice.jpeg",
    "category": "Fried Rice",
    "quarter": null,
    "half": 140,
    "full": 240,
    "family": null
  },
  {
    "name": "Chicken Fried Rice",
    "price": null,
    "description": "Savory fried rice with tender chicken pieces for a tasty experience.",
    "image": "./assets/image/MenuImages/Chicken Fried Rice.jpeg",
    "category": "Fried Rice",
    "quarter": null,
    "half": 150,
    "full": 270,
    "family": null
  },
  {
    "name": "Chicken Schezwan Fried Rice",
    "price": null,
    "description": "Spicy Schezwan rice with chicken for an extra flavorful meal.",
    "image": "./assets/image/MenuImages/Chicken Schezwan Fried Rice.jpg",
    "category": "Fried Rice",
    "quarter": null,
    "half": 160,
    "full": 280,
    "family": null
  },
  {
    "name": "Chicken Chilli Garlic Fried Rice",
    "price": null,
    "description": "Fried rice with a bold blend of chili and garlic flavors, topped with chicken.",
    "image": "./assets/image/MenuImages/Chicken Chilli Garlic Fried Rice.jpeg",
    "category": "Fried Rice",
    "quarter": null,
    "half": 160,
    "full": 280,
    "family": null
  },
  {
    "name": "Veg Noodles",
    "price": null,
    "description": "Classic noodles stir-fried with mixed vegetables, a timeless favorite.",
    "image": "./assets/image/MenuImages/Veg Noodles.jpg",
    "category": "Noodles",
    "quarter": null,
    "half": 120,
    "full": 220,
    "family": null
  },
  {
    "name": "Paneer Noodles",
    "price": null,
    "description": "Stir-fried noodles with paneer cubes and vegetables, perfect for vegetarians.",
    "image": "./assets/image/MenuImages/Paneer Noodles.jpg",
    "category": "Noodles",
    "quarter": null,
    "half": 140,
    "full": 240,
    "family": null
  },
  {
    "name": "Veg Chilli Garlic Noodles",
    "price": null,
    "description": "Spicy garlic-flavored noodles with chili and mixed veggies.",
    "image": "./assets/image/MenuImages/Veg Chilli Garlic Noodles.jpg",
    "category": "Noodles",
    "quarter": null,
    "half": 130,
    "full": 230,
    "family": null
  },
  {
    "name": "Veg Schezwan Noodles",
    "price": null,
    "description": "Schezwan-style spicy noodles with mixed vegetables for a hot treat.",
    "image": "./assets/image/MenuImages/Veg Schezwan Noodles.jpg",
    "category": "Noodles",
    "quarter": null,
    "half": 130,
    "full": 230,
    "family": null
  },
  {
    "name": "Egg Noodles",
    "price": null,
    "description": "Flavorful egg noodles with mixed veggies, ideal for a quick meal.",
    "image": "./assets/image/MenuImages/Egg Noodles.webp",
    "category": "Noodles",
    "quarter": null,
    "half": 140,
    "full": 240,
    "family": null
  },
  {
    "name": "Chicken Noodles",
    "price": null,
    "description": "Savory chicken noodles with vegetables for a satisfying meal.",
    "image": "./assets/image/MenuImages/Chicken Noodles.jpg",
    "category": "Noodles",
    "quarter": null,
    "half": 150,
    "full": 270,
    "family": null
  },
  {
    "name": "Chicken Schezwan Noodles",
    "price": null,
    "description": "Spicy chicken noodles with Schezwan sauce for heat lovers.",
    "image": "./assets/image/MenuImages/Chicken Schezwan Noodles.jpg",
    "category": "Noodles",
    "quarter": null,
    "half": 160,
    "full": 280,
    "family": null
  },
  {
    "name": "Chicken Chilli Garlic Noodles",
    "price": null,
    "description": "Chicken noodles tossed in chili-garlic sauce for a fiery twist.",
    "image": "./assets/image/MenuImages/Chicken Chilli Garlic Noodles.jpeg",
    "category": "Noodles",
    "quarter": null,
    "half": 160,
    "full": 280,
    "family": null
  },
  {
    "name": "Sweet Corn Soup",
    "price": 100,
    "description": "A creamy, sweet corn soup for a light and warming start.",
    "image": "./assets/image/MenuImages/Sweet Corn Soup.jpeg",
    "category": "Soups",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null
  },
  {
    "name": "Chilli Paneer Gravy (8/16 Pcs)",
    "price": null,
    "description": "A flavorful gravy with spicy paneer pieces for a delightful meal.",
    "image": "./assets/image/MenuImages/Chilli Paneer Gravy.jpeg",
    "category": "Chinese Main Course",
    "quarter": null,
    "half": 170,
    "full": 290,
    "family": null
  },
  {
    "name": "Veg Manchurian Gravy (8/16 Pcs)",
    "price": null,
    "description": "Savoury veg balls in a thick, spicy Manchurian sauce.",
    "image": "./assets/image/MenuImages/Veg Manchurian Gravy.jpeg",
    "category": "Chinese Main Course",
    "quarter": null,
    "half": 150,
    "full": 270,
    "family": null
  },
  {
    "name": "Chilli Chicken Gravy (8/16 Pcs)",
    "price": null,
    "description": "Succulent chicken pieces in a spicy chili gravy sauce.",
    "image": "./assets/image/MenuImages/Chilli Chicken Gravy.jpeg",
    "category": "Chinese Main Course",
    "quarter": null,
    "half": 200,
    "full": 330,
    "family": null
  },
  {
    "name": "Paneer Manchurian Gravy (8/16 Pcs)",
    "price": null,
    "description": "Paneer cubes in a rich, spicy Manchurian sauce.",
    "image": "./assets/image/MenuImages/Paneer Manchurian Gravy.jpeg",
    "category": "Chinese Main Course",
    "quarter": null,
    "half": 200,
    "full": 300,
    "family": null
  },
  {
    "name": "Chicken Manchurian Gravy (8/16 Pcs)",
    "price": null,
    "description": "Tender chicken in a tangy Manchurian sauce.",
    "image": "./assets/image/MenuImages/Chicken Manchurian Gravy.jpeg",
    "category": "Chinese Main Course",
    "quarter": null,
    "half": 200,
    "full": 340,
    "family": null
  },
  {
    "name": "Hot N Sour Soup",
    "price": 100,
    "description": "A spicy and tangy soup to ignite your taste buds.",
    "image": "./assets/image/MenuImages/Hot N Sour Soup.jpeg",
    "category": "Soups",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null
  },
  {
    "name": "Veg Peking Soup",
    "price": 100,
    "description": "A light, savory vegetable soup for a warm start.",
    "image": "./assets/image/MenuImages/Veg Peking Soup.jpeg",
    "category": "Soups",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null
  },
  {
    "name": "Chicken Hot N Sour Soup",
    "price": 120,
    "description": "A fiery and tangy soup with chicken for spice lovers.",
    "image": "./assets/image/MenuImages/Chicken Hot N Sour Soup.jpeg",
    "category": "Soups",
    "quarter": null,
    "half": null,
    "full": 100,
    "family": null
  },
  {
    "name": "Chicken Peking Soup",
    "price": 120,
    "description": "A flavorful chicken soup to warm up your taste buds.",
    "image": "./assets/image/MenuImages/Chicken Peking Soup.jpeg",
    "category": "Soups",
    "quarter": null,
    "half": null,
    "full": 100,
    "family": null
  },
  {
    "name": "Paneer Chilli/Veg Manchurian",
    "price": 160,
    "description": "A combo of paneer chili or veg Manchurian served with rice or noodles.",
    "image": "./assets/image/MenuImages/Paneer Chilli Veg Manchurian.jpeg",
    "category": "Chinese Combos",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null
  },
  {
    "name": "Chicken Chilli/Chicken Manchurian",
    "price": 180,
    "description": "A combo of chicken chili or Manchurian served with rice or noodles.",
    "image": "./assets/image/MenuImages/Chicken Chilli Chicken Manchurian.jpeg",
    "category": "Chinese Combos",
    "quarter": null,
    "half": null,
    "full": null,
    "family": null
  }
]

export default Menu;
